<template>
  <div>
    <div class="tot">
      <div>今日数据</div>
      <div>累计数据</div>
    </div>
    <!-- 具体信息 -->
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日用户总览</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日活跃人数</div>
                    <div>{{ data.active }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日注册用户</div>
                    <div>{{ data.userNum }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日成交人数</div>
                    <div>{{ data.turnoverUserNum }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日实名认证人数</div>
                    <div>{{ data.passAthen || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>总用户总览</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总活跃人数</div>
                    <div>{{ data.activeAll }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总注册用户</div>
                    <div>{{ data.userNumAll }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总日成交人数</div>
                    <div>{{ data.turnoverUserNumAll }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总实名认证人数</div>
                    <div>{{ data.passAthenAll }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日买入手续费</div>
                    <div>{{ $setNumbers(data.buyServiceCharge) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日卖出手续费</div>
                    <div>{{ $setNumbers(data.saleServiceCharge) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日流转手续费</div>
                    <div>{{ $setNumbers(data.ousstServiceCharge) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日成交金额</div>
                    <div>{{ $setNumbers(data.turnoverAmount) || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>累计交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总买入手续费</div>
                    <div>{{ $setNumbers(data.buyServiceChargeAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总卖出手续费</div>
                    <div>{{ $setNumbers(data.saleServiceChargeAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总流转手续费</div>
                    <div>{{ $setNumbers(data.outServiceChargeAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总成交金额</div>
                    <div>{{ $setNumbers(data.turnoverAmountAll) || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日通证转入数量</div>
                    <div>{{ data.tokenInNum || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日通证转出数量</div>
                    <div>{{ data.tokenOutNum || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>今日成交量</div>
                    <div>{{ data.turnoverNum || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>累计交易统计</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总通证转入数量</div>
                    <div>{{ $setNumbers(data.tokenInNumAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总通证转出数量</div>
                    <div>{{ $setNumbers(data.tokenOutNumAll) || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>总成交量</div>
                    <div>{{ $setNumbers(data.turnoverNumAll) || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="12" style="margin: 20px 0">
          <div class="tot-del">
            <span>今日提现量</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>提现手续费</div>
                    <div>{{ data.withdrawServerChange || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>累计提现量</div>
                    <div>{{ data.withdrawAmountAll || 0 }}</div>
                  </div></el-col
                >
                <el-col :span="6"
                  ><div class="grid-content bg-purple">
                    <div>累计提现手续费</div>
                    <div>{{ data.withdrawServerChangeAll || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="tot-d">
      <el-row :gutter="20">
        <el-col :span="24" style="margin: 20px 0">
          <div class="tot-del">
            <span>通证</span>
            <div class="tot-dels">
              <el-row :gutter="20">
                <el-col :span="4" v-for="(t, i) in passData" :key="i">
                  <div class="grid-content bg-purple">
                    <div>{{ t.tokenName }}</div>
                    <div>交易量：{{ t.num || 0 }}</div>
                    <div>交易总额：{{ t.amount || 0 }}</div>
                  </div></el-col
                >
              </el-row>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: '',
      passData: ''
    }
  },
  created() {
    this.getShowPage()
    this.getPassList()
  },
  methods: {
    getShowPage() {
      this.axios.get('/admin/page/showPage', {}).then(res => {
        if (res.data.code == 200) {
          this.data = res.data.data
        } else {
          this.$message({
            message: '获取失败,请检查网络',
            type: 'error'
          })
        }
      })
    },
    getPassList() {
      this.axios.get('/admin/turnover/getCertificate', {}).then(res => {
        if (res.data.code == 200) {
          this.passData = res.data.data
        } else {
          this.$message({
            message: '获取失败,请检查网络',
            type: 'error'
          })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tot {
  min-width: 1000px;
  padding: 30px;
  display: flex;
  justify-content: center;

  div {
    min-width: 100px;
    margin: 0 50px;
    padding: 5px 30px;
    border: 1px solid #ebeef5;
    border-radius: 4px;
    text-align: center;
  }
}

.tot-del {
  border: 1px solid #ebeef5;

  span {
    display: inline-block;
    padding: 18px 20px;
  }
}
.tot-dels {
  padding: 10px;
}
.el-row {
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  background-color: #a2b7ce;
  padding: 5px;
  border-radius: 4px;
  min-height: 36px;
  opacity: 0.5;
  div {
    text-align: center;
  }
}
</style>
